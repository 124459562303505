.react-multiple-carousel__arrow {
  border-radius: 50% !important;
  min-width: 35px !important;
  min-height: 35px !important;
}

.react-multiple-carousel__arrow::before {
  font-size: 13px !important;
}

.MuiSlider-markLabel,
.MuiSlider-markLabelActive {
  font-size: 11px !important;
  top: 35px !important;
}

.create_ad_dialog {
  z-index: 11000 !important;
  /* border-radius: 20px !important; */
}

.create_ad_dialog .MuiBackdrop-root {
  opacity: .6 !important;
  background-color: #f4f4f4;
}

.create_ad_dialog .MuiPaper-rounded {
  border-radius: 4px !important;
}

#menu-categories, #menu-subcategories {
  z-index: 11100 !important;
}

.MuiDialog-paper  {
  /* border-radius: 20px !important; */
  box-shadow: 0 3px 16px 3px rgba(37,50,56,.2) !important;
}

.CookieConsent {
  background: #fff !important;
  position: fixed;
  z-index: 12001;
  color: #000;
  bottom: 25vh !important;
  max-width: 50rem;
  padding: .5rem 2rem 2rem;
  border-radius: 20px;
  left: 10%;
  right: 10%;
  margin: auto;
}

.CookieConsent div:nth-child(2) {
  text-align: right;
}

.CookieConsent button {
  color: #fff;
  font-size: 13px;
  background-color: #7300bd;
  border-radius: 20px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

/* Toastify__toast-container */
.Toastify__toast-container {
  z-index: 19999 !important;
}

.MuiPaper-rounded {
  border-radius: 11px !important;
}

/* global */
.btn-primary {
  background: linear-gradient(45deg, #4147b1, #c36bd6) !important;
  border-radius: 20px !important;
}

.btn-primary:hover {
  background-color: #7300bd !important;
  opacity: .8 !important;
}

.btn-primary span {
  color: #fff !important;
}

@media only screen and (max-width: 920px) {
  .CookieConsent {
    font-size: 16px;
    bottom: 20vh !important;
    margin: auto;
    width: 75%;
    left: 0;
    right: 0;

  }
}

@media only screen and (max-width: 700px) {
  .CookieConsent {
    font-size: 14px;
    bottom: 15vh !important;
  }
}

@media only screen and (max-width: 400px) {
  .CookieConsent {
    bottom: 5vh !important;
  }
}

@media only screen and (max-height: 700px) {
  .CookieConsent {
    font-size: 14px;
    height: 90vh;
    bottom: 2vh !important;
    top: 2vh;
    overflow-y: scroll;
  }
}