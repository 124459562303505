@font-face {
  font-family: 'SFCompactDisplay';
  font-weight: 700;
  src: local('SFCompactDisplay'), url('./assets/fonts/SFCompactDisplayBold.ttf') format('truetype');
}

@font-face {
  font-family: 'SFCompactDisplay';
  font-weight: 600;
  src: local('SFCompactDisplay'), url('./assets/fonts/SFCompactDisplayMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'SFCompactDisplay';
  font-weight: 400;
  src: local('SFCompactDisplay'), url('./assets/fonts/SFCompactDisplayRegular.ttf') format('truetype');
}


body {
  margin: 0;
  font-family: 'SFCompactDisplay', 'Source Sans Pro', 'Helvetica', 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #253238;
}